
$gray-900:        #000;
$gray-800:        #161616;
$gray-700:        #292b2c;
$gray-600:        #5E5E5E;
$gray-500:        #686868;
$gray-400:        #737373;
$gray-300:        #929292;
$gray-200:        #A4A4A4;
$gray-100:        #F3F5F5;

$primary:         #B89459;
$primary-dark:    #3a3831;
$success:         #2c7d57;
$info:            #03b0c0;
$warning:         #FC991C;
$danger:          #FC3D1C;
$secondary:       $gray-500;

$font:            #FFFFFF;
$font-dark:       $gray-900;

$border-radius:      0px;
$border-radius-lg:   5px;
$border-color:       $gray-100;