// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;400;700&family=Oswald:wght@200;400&family=Raleway:ital,wght@0,700;1,100&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Unicase:wght@600&family=Noto+Sans+TC&family=Noto+Serif+TC:wght@600&display=swap')

// font-family: 'Cormorant Unicase', serif 600;
// font-family: 'Noto Sans TC', sans-serif 400;
// font-family: 'Noto Serif TC', serif 600;
@import "reset"
@import "helpers/variables"
@import "helpers/extend"
@import "helpers/mixin"
@import "bootstrap"

.ratio-hero-video
  @extend %img-ratio
  padding-bottom: 42%
.object-fill-cover
  object-fit: cover
.object-fill-contain
  object-fit: contain

[v-cloak]
  display: none
.hero-video
  iframe
    width: 100vw
    height: 56.25vw
    min-height: 100vh
    min-width: 120vh
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    pointer-events: none

.fix-transform-bug
  -webkit-transform-style: preserve-3d
//
body
  position: relative
  background-color: black
  color: $font
  font-size: 14px
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Noto Sans TC', 'Raleway', "Oswald", Roboto, 'Microsoft JhengHei', "Microsoft YaHei", "Apple Color Emoji", Helvetica, Arial, sans-serif
  &.modal-open
    padding-right: 0px !important
    .mm-slideout
      z-index: inherit !important
.bodoni
  font-family: 'Didot', 'Cormorant Unicase', 'Noto Serif TC', Helvetica, Arial, serif
  font-weight: bold
a
  color: $font
  text-decoration: none
  &:hover,&:focus,&:active,&.active
    color: $primary
    text-decoration: none
    outline: 0
  &.primary-link
    color: $primary
    text-decoration: underline
    &:hover,&:focus,&:active,&.active
      color: $primary

.form-control
  background: #000
  color: $font
  &:focus
    background: #000
    color: $font

.opacity-50
  opacity: 0.5
.opacity-80
  opacity: 0.8

.btn
  display: inline-flex
  align-items: center
  justify-content: center
  transition: .2s all ease-out
  border-radius: 0
  &:focus
    outline: 0
    box-shadow: none
  &.more-y
    padding-left: 1.2rem
    padding-right: 1.2rem
  &.btn-outline-white
    border-color: #FFFFFF
    color: $primary
  .arr-right
    display: inline-block
    width: 8px
    margin-left: .5rem
    svg
      width: 100%
      float: left
  &.max-width-210
    max-width: 210px
    width: 100%
    &:hover
      letter-spacing: 2px
  &.w-100
    &:hover
      letter-spacing: 2px
  &.btn-primary
    color: #fff
  &.btn-danger
    color: #fff
    &:hover,&:focus
      color: #fff
  &.btn-line
    color: #fff
    background-color: #01C101
    &:hover,&:focus
      color: #fff
      background-color: darken(#01C101, 10%)

@keyframes animatedBackground
  0%
    background-position: 0 0
  100%
    background-position: 300% 0

.page-header
  top: 0
  left: 0
  width: 100%
  z-index: 999
  background-color: #fff
  // position: fixed
  // top: 0
  // left: 0
  // width: 100%
  +sm-down
    position: relative
  .is-sticky  &
    z-index: 1203 !important
    background: #FFF
    @extend %box-shadow
  .navbar
    justify-content: initial
    +sm-down
      padding: 0
      justify-content: center
    .is-sticky &
      padding: .25rem 0
    .navbar-collapse
      justify-content: flex-end
    .menutoggler
      border: 0
      width: 50px
      height: 50px
      display: flex
      align-items: center
      justify-content: center
      position: absolute
      top: 50%
      left: 0
      transform: translateY(-50%)
      &:hover, &:focus
        outline: 0
        .menu-icon
          &_bar
            background-color: $primary
    .navbar-brand
      font-size: 2.2rem
      +sm-down
        margin-top: .25rem
        margin-bottom: .25rem
        font-size: 1.62rem
  .navbar-nav
    font-weight: 200
    font-size: 1rem
    flex-direction: row
    .nav-item
      position: relative
      font-weight: 400
      padding: 0 .5rem
    .nav-link
      color: #FFFFFF
      position: relative
      z-index: 10
      padding: 1rem
      display: flex
      align-items: center
      font-size: .9rem
      +md-down
        padding: .75rem 1rem
      &:hover, &:focus, &:active, &.active
        color: $primary
        font-weight: blod
        &::before
          width: 80%
      .is-sticky &
        font-size: .9rem
      .text
        z-index: 30
        position: relative
      .icon
        width: 17px
        display: inline-block
        margin-right: .3rem
        path
          fill: #ffffff
      &::before
        @extend %menu-animation
        animation-delay: .2s
        content: ''
        display: block
        position: absolute
        height: 2px
        width: 0%
        left: 50%
        bottom: 0
        transform: translateX(-50%)
        max-width: auto
        background-color: $primary
        z-index: 0
    .dropdown
      &.fullwide
        position: static
        .submenu-wrapper
          > .submenu
            padding-left: 0
        .dropdown-menu
          width: 100%
          margin-top: 0
          background: $gray-100
        .dropdown-item
          padding-left: 0
          padding-right: 0
          &.title
            +font-size(16px)
            font-weight: 600
            border-bottom: 1px solid $gray-800
            margin-bottom: 12px
            padding-bottom: 12px
        .submenu
          &.lv2
            padding-left: 0
            > li
              > .dropdown-item
                font-weight: 600
                +font-size(15px)
          &.lv3
            margin-bottom: 10px
            padding-left: 10px
            > li
              > .dropdown-item
                padding: .15rem 0
                +font-size(13px)
      &.wide
        position: static
        .dropdown-menu
          width: 100%
          margin-top: 0
          background: $gray-100
          left: 0
          top: 100%
        .submenu-wrapper
          width: 800px
          margin: 0 auto
          display: flex
        .submenu
          padding: 0
          margin: 0
          width: auto
          &.lv1
            position: relative
            .submenu
              position: absolute
              top: 0
              left: 100%
              box-shadow: none
          > li
            &:hover, &:focus
              > a
                color: $primary
              > .submenu
                display: block
                height: 100%
                opacity: 1
                visibility: visible
                padding-left: 15px
                min-width: 280px
          .dropdown-item
            +font-size(15px)
            padding-right: 25px
            padding-left: 0
            white-space: normal
            &.has-submenu
              position: relative
              &::after
                content: ''
                display: block
                width: 5px
                height: 9px
                background: url('../images/arr-right.svg') no-repeat
                background-size: 100%
                position: absolute
                top: 50%
                right: 5px
                transform: translateY(-50%)
    .dropdown-menu
      background: #ffffff
      display: block
      opacity: 0
      visibility: hidden
      @extend %menu-animation
      box-shadow: 0px 10px 10px rgba(0,0,0,0.3)
      border-radius: 0
      border: 0
      &.show
        opacity: 1
        visibility: visible
      .dropdown-item
        color: $gray-800
        +font-size(14px)
        padding: .35rem 1rem
        &:hover,&:focus
          color: $primary
          background: transparent
  .navbar-brand
    padding: 0
    +lg-down
      margin: 0 auto
    +sm-down
      margin: .75rem auto
    .header-logo
      width: 240px
      transition: .3s width ease-out
      +md-down
        width: 148px
      .is-sticky &
        width: 216px
        +md-down
          width: 90px
    .branding-name
      +font-size(24px)
      color: $gray-900
      font-weight: 500
      margin-bottom: 0
  .header-search
    width: 202px
    display: flex
    +lg-down
      width: auto
      position: absolute
      right: 0
    form.form-search
      &:hover
        .customize-input
          width: 136px
          padding: 0.375rem 0.25rem
          height: 36px
          text-align: left
          border-bottom: 1px solid $gray-900
    .btn-search
      display: flex
      align-items: center
      &:hover
        .text
          color: $primary
        svg
          g
            stroke: $primary
            // +sm-up
            //   stroke: $white
      .text
        margin-right: .25rem
    .icn
      width: 17px
      display: inline-block
      line-height: 0
      +sm-down
        width: 20px
      svg
        width: 100%
    ::placeholder
      color: $gray-900
    ::-webkit-input-placeholder
      color: $gray-900
    :-ms-input-placeholder
      color: $gray-900
    ::-moz-placeholder
      color: $gray-900

    .customize-input
      border: 0
      color: $gray-900
      width: 50px
      transition: width .2s ease-out
      padding: 0.375rem .5rem
      text-align: right
      &:focus, &:active
        width: 136px
        outline: none
        padding: 0.375rem 0.25rem
        height: 36px
        text-align: left
        border-bottom: 1px solid $gray-900
      .is-sticky &
        font-size: .9rem
.mm-menu
  // background: $gray-700
  &.mm-theme-dark
    background: $gray-700
    .mm-listview
      .nav-item
        &.active,&:hover,&:focus
          color: $primary
          background: darken($gray-700, 5%)
          .nav-link, .mm-next
            color: $primary
        .nav-link, .mm-next
          color: $white
      a
        color: $white
        &.active,&:hover,&:focus
          color: $primary
.slick-slider
  .slick-track
    transform: none
  .slick-list
    transform: none

.slick-slide
  img
    width: 100%
.slick-arrow
  position: absolute
  bottom: 30px
  text-indent: 999px
  overflow: hidden
  width: 50px
  height: 50px
  background: transparent
  border: 0
  z-index: 561
  background: url('../images/slick-prev.svg') no-repeat center center
  background-size: 65% auto
  opacity: 0.8
  +md-down
    bottom: 50%
    margin-top: 25px
  &:hover, &:focus
    outline: 0
    opacity: 1
  &.slick-disabled
    opacity: 0.5
  &.slick-prev
    left: 35%
    +md-down
      left: 10px
  &.slick-next
    left: calc(35% + 60px)
    transform: scaleX(-1)
    +md-down
      left: auto
      right: 10px
.slick-dots
  margin: 0
  padding: 0
  text-align: center
  position: absolute
  left: 10%
  bottom: 50px
  width: 80%
  display: flex
  justify-content: flex-end
  +md-down
    justify-content: center
    bottom: 30px
  > li
    display: inline-block
    padding: 0
    &:after
      content: ''
      display: block
      width: 20px
      height: 1px
      background: #FFF
      padding: 0
      margin: 0
    &:first-child, &:last-child
      > button
        font-size: 13px
        color: #FFF
        width: 20px
        height: 20px
        position: absolute
        overflow: auto
        top: -10px
    &:first-child
      > button
        margin-left: -20px
    &:last-child
      > button
        margin-left: 20px
    &.slick-active
      &:after
        background: $primary
        border-color: $primary
    > button
      -webkit-appearance: none
      border: 0
      display: block
      width: 20px
      height: 1px
      font-size: 0
      background: transparent
      position: absolute
      &:hover,&:focus,&:active,&.active
        outline: 0 !important

//default state
$mu-icon-size:  24px
.menu-icon
  // height: $mu-icon-size
  width: 24px
  // position: absolute
  z-index: 2
  cursor: pointer
  &_bar
    height: 2px
    width: 24px
    display: block
    background-color: #FFFFFF
    margin-bottom: 6px
    transition: transform .2s ease, background-color .5s ease

  &_bar-first
    // width: $mu-icon-size / 2
  &_bar-last
    margin-bottom: 0
    // width: $mu-icon-size / 2
    float: right


.mm-opened
  .mm-page
    overflow: hidden
  // #LaunchPage
  //   position: relative
  .menu-icon
    &_bar
      transform: translateX(0px) rotate(-45deg)
    &_bar-first
      transform: translate(1px, 3px) rotate(45deg)
      width: calc($mu-icon-size / 2)
    &_bar-last
      transform: translate(-1px, -3px) rotate(45deg)
      width: calc($mu-icon-size / 2)
      float: right

section
  &.border-top
    border-color: rgba($gray-200, 0.2) !important
  &.spacing-y
    padding-top: 80px
    padding-bottom: 80px
  &.home-upcoming
    overflow: hidden
  &.section-padding
    padding: 5rem 0
    +md-down
      padding: 3rem 0
  .section-container
    padding-left: 20px
    padding-right: 20px
    +md-up
      padding-left: 80px
      padding-right: 80px
    +lg-up
      padding-left: 100px
      padding-right: 100px
.article-content
  .side-bar
    &.sticky-top
      z-index: 678
    .accordion-item
      border: 0
      &:not(:last-of-type)
        border-bottom: 1px solid darken($border-color, 5%) !important
      &:first-of-type, &:last-of-type
        border-radius: 0
        .accordion-button
          border-radius: 0
      .accordion-button
        +font-size(22px)
        font-weight: 600
        box-shadow: none
        +sm-down
          +font-size(20px)
          padding: 12px 8px
        &:not(.collapsed)
          background: transparent
    .accordion-body
      padding-top: 0
      padding-bottom: 0
      +sm-down
        padding: 0 8px
      ul
        padding: 0
        li
          +font-size(16px)
          padding: 0.5rem 0
          a
            color: $gray-700
            &:hover,&:active,&:focus,&.active
              color: $primary


.article-heading-group
  margin-bottom: 34px
  width: 100%
  overflow: hidden
  +md-down
    margin-bottom: 2rem
  +sm-down
    margin-bottom: 1rem
  .title
    +font-size(36px)
    color: $gray-900
    font-weight: 500
    +md-down
      +font-size(28px)
    +sm-down
      +font-size(24px)
  .date
    +font-size(16px)
    color: $primary
    font-weight: 400
.section-heading-group
  margin-bottom: 34px
  width: 100%
  overflow: hidden
  +md-down
    margin-bottom: 2rem
  +sm-down
    margin-bottom: 1rem
  .title
    +font-size(48px)
    color: #FFF
    text-transform: uppercase
    font-weight: 500
    +md-down
      +font-size(38px)
    +sm-down
      +font-size(30px)
    &.bordered
      overflow: hidden
      span
        display: inline-block
        position: relative
        &:after
          content: ''
          display: block
          width: 2000px
          height: 1px
          background: $primary
          position: absolute
          top: 50%
          left: calc(100% + 20px)
  .subtitle
    text-transform: uppercase
    color: #FFF
    +font-size(16px)
    letter-spacing: 5px
    font-weight: normal
    margin-bottom: 0
    line-height: 1.57
    +md-down
      +font-size(13px)
      letter-spacing: 3px
    &.size-lg
      +font-size(36px)
      +md-down
        +font-size(20px)
    &.bordered
      overflow: hidden
      span
        display: inline-block
        position: relative
        &:after
          content: ''
          display: block
          width: 2000px
          height: 1px
          background: $primary
          position: absolute
          top: 50%
          left: calc(100% + 20px)

.hero-banner
  position: relative
  .slide-item
    position: relative
    .more
      position: absolute
      bottom: 0
      right: 73.45%
      +sm-down
        display: none
      .btn
        padding: 1.5rem 2.8rem
        +lg-down
          padding: 1.2rem 2rem
    .item-img
      +md-down
        position: relative
        padding-bottom: 110%
      img
        +md-down
          position: absolute
          width: 100%
          height: 100%
          top: 0
          left: 0
          object-fit: cover
.home-categories
  background: url('../images/home-categories-bg.jpg') no-repeat left center
  background-size: auto 100%
  .categories-content
    padding: 20px 50px
    +md-up
      margin-left: 20%
    .categories-slick
      margin-left: -15px
      margin-right: -15px
      padding-top: 30px
      padding-bottom: 20px
      .slick-arrow
        bottom: auto
        top: -50px
        left: auto
        &.slick-prev
          right: 70px
        &.slick-next
          right: 0
      .slick-dots
        right: 30px
        left: auto
        bottom: -40px
        width: auto
    .card-cate
      margin: 0 15px
      +md-down
        margin: 0 8px
      .card-body
        text-align: center
      .title
        +font-size(18px)
        color: #FFF
        margin-bottom: 5px
      .name
        +font-size(14px)
        margin-bottom: 0
.home-about
  margin-top: 50px
  +md-up
    margin-top: 120px
  .section-content
    +md-up
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 35px
    .gallerys
      display: grid
      grid-template-columns: 2fr 3fr
      grid-gap: 35px
      align-items: flex-end
      margin-top: -160px
      +lg-down
        grid-gap: 20px
      +md-down
        grid-gap: 15px
        margin-top: 15px
        margin-bottom: 15px
      img
        box-shadow: 0 0 15px rgba(0,0,0,.3)
.about-vision
  padding-top: 50px
  padding-bottom: 30px
  +md-up
    padding-top: 120px
    padding-bottom: 60px
  .section-content
    +md-up
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 35px
    .gallerys
      display: grid
      grid-template-columns: 3fr 2fr
      grid-gap: 35px
      align-items: flex-end
      margin-top: -160px
      +lg-down
        grid-gap: 20px
      +md-down
        grid-gap: 15px
        margin-bottom: 50px
      img
        box-shadow: 0 0 15px rgba(0,0,0,.3)

.home-idea
  .photo-group
    display: flex
    align-items: stretch
    flex-direction: column
    .photo-item
      &:first-child
        flex: 0 0 60%
        height: 60%
      &:last-child
        flex: 0 0 40%
        height: 40%
      &.bg-primary
        background: #B89459
      img
        height: 100%
        width: 100%
        object-fit: cover

  .photo-item
    position: relative
    .content
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      .section-heading-group
        max-width: 70%
        text-shadow: 0 0 5px rgba(0,0,0,.5)
        margin: 0 auto
.page-footer
  background-color: #000
  border-top: 1px solid #545454
  color: #FFFFFF
  .footer-below
    background-color: #151515
  .copyright
    padding: 1rem 0
    font-size: 0.875rem
    color: rgba(255,255,255,.5)
    text-align: center
  .footer-above
    padding: 3rem 0
    +md-down
      padding: 2rem 0
    +sm-down
      padding: 2.8rem 0
    .above-content
      display: flex
      flex-wrap: wrap
      position: relative
      +sm-down
        align-items: center
  .footer-navi
    +font-size(14px)
    font-weight: 400
    padding: 0
    > li
      &:not(:last-child)
        +sm-down
          margin-bottom: 1.25rem
    h4
      font-weight: 500
      +font-size(16px)
      margin-bottom: 1rem
      +md-down
        margin-bottom: 0.25rem
        font-weight: 600
    ul
      margin: 0
      padding: 0
      li
        padding: .25rem 0
    a
      text-decoration: underline
      &:hover,&:focus,&:active,&.active
        color: $primary
    b
      font-weight: 500
  .brand-area
    margin-bottom: .5rem
    +md-down
      margin-bottom: 1rem
    .brand-img
      max-width: 96px
      +sm-down
        max-width: 68px
    h3
      +font-size(28px)
      font-weight: 500
      margin-bottom: 0
  .company-desc
    padding-right: 5rem
    line-height: 1.3
    +lg-down
      padding-right: 3rem
    +md-down
      flex: 0 0 100%
      width: 100%
      margin-bottom: 1.5rem
      padding-right: 0
    p
      +font-size(14px)
      margin-bottom: 10px
    .artist
      +font-size(13px)
      font-weight: 300
      margin-bottom: 0
  .company-info
    +font-size(14px)
    line-height: 1.3
    ul
      padding-left: 0
      margin-bottom: 0
      li
        &:not(:last-child)
          margin-bottom: 6px
    +md-down
      flex: 0 0 100%
      width: 100%
.about-kv
  position: relative
  .item-img
    height: 88vh
    position: relative
    +md-down
      height: 68vh
      min-height: 360px
    img
      height: 100%
      object-fit: cover
  .inner
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    +md-down
      padding-right: 20%
      padding-bottom: 40px

.page-kv
  height: 20vw
  position: relative
  img
    height: 100%
    object-fit: cover
  &.gradient
    &:after
      content: ''
      display: block
      width: 100%
      height: 50%
      position: absolute
      bottom: 0
      left: 0
      background: linear-gradient(0deg, rgba(0,0,0,0.8), rgba(0,0,0,0))
      z-index: 8
  .breadcrumb_nav
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    z-index: 80
    .breadcrumb
      a
        color: #fff
        font-weight: 600
      .breadcrumb-item
        color: #fff
      .breadcrumb-item + .breadcrumb-item
        &::before
          color: #fff
.breadcrumb
  font-size: .9rem
  a
    color: $font-dark
    font-weight: 500
.collection-search
  margin-bottom: 3rem
  &.mt-negative
    margin-top: -8rem
    +md-down
      margin-top: -12vw
  .breadcrumb
    a
      color: #fff
      font-weight: 600
    .breadcrumb-item
      color: #fff
    .breadcrumb-item + .breadcrumb-item
      &::before
        color: #fff
  .search-panel
    box-shadow: 0 0 20px rgba(0,0,0,.5)
    border-radius: $border-radius
    position: relative
    z-index: 812
    .nav-tabs
      border-bottom: 0
      width: 100%
      display: flex
      .nav-item
        max-width: 180px
        width: 33.3333%
        padding-right: 1px
      .nav-link
        width: 100%
        margin-bottom: -2px
        padding: .75rem 1rem
        color: $font-dark
        background-color: rgba(255,255,255,.8)
    .searchTab-content
      background-color: #fff
      padding: 2rem
      border-radius: 0 $border-radius $border-radius $border-radius
      position: relative
      z-index: 876
      +md-down
        border-radius: 0 0 $border-radius $border-radius
    .form-select, .form-control
      &.border-bottom
        border-radius: 0
        border: 0
        border-bottom: 1px solid $gray-600 !important
    .form-check-label
      font-size: .9rem
    .dropdown-wrapper
      &.dropOpen
        .select-dropdown
          display: block
    .select-dropdown
      border-radius: $border-radius
      box-shadow: 0 0 10px rgba(0,0,0,.25)
      padding: 1rem 2rem
      left: 0
      margin-top: .5rem
      position: absolute
      width: 100%
      z-index: 561
      background-color: #fff
      display: none
      .tab-content
        .tab-pane
          padding: 1rem 0
      .form-check
        label
          font-size: .9rem
.form-check
  .form-check-input
    +md-down
      margin-top: .65rem
  .form-check-label
    +md-down
      line-height: 2
.nav-line
  .nav-link
    background-color: transparent
    border: 0
    position: relative
    color: $font-dark
    font-size: .9rem
    &.active
      color: $danger
      border-bottom: 2px solid $danger

.pagination
  display: flex
  align-items: center
  justify-content: center
  margin-top: 2rem
  margin-bottom: 2rem
  .page-item
    &:hover, &:focus, &:active, &.active
      .page-link
        background-color: $primary
        color: $white
        border-color: $primary
    &.disabled
      .page-link
        background-color: rgba($gray-200,0.2)
        color: $gray-500
        border-color: $gray-200
        opacity: .5
    .page-link
      border-radius: 50%
      width: 36px
      height: 36px
      margin: 0 5px
      display: flex
      align-items: center
      justify-content: center
      padding: 0
      text-align: center
      background-color: transparent
      color: $font
      +font-size(16px)
      background: #000
      border: 1px solid $gray-500
      +sm-down
        +font-size(14px)
      &.previous, &.next
        +md-up
          width: auto
          padding: 0 12px
          border-radius: 50px
      &.previous
        +md-up
          padding-right: 20px
      &.next
        +md-up
          padding-left: 20px
      svg
        width: 20px

.static-content
  color: $font
  +font-size(14px)
  line-height: 1.85
  &.aboutus
    h2
      +font-size(52px)
      font-weight: 300
      color: $gray-900
      +md-down
        +font-size(46px)
      +sm-down
        +font-size(32px)
    h3
      +font-size(28px)
      font-weight: 500
      +lg-down
        +font-size(18px)
      +md-down
        +font-size(20px)
    p, li
      +font-size(16px)
      margin-bottom: 0.5rem
      +md-down
        +font-size(15px)
    ul
      padding-left: 0
      li
        list-style-position: inside
        > ul, ol
          padding-left: 20px
        > ol
          list-style: cjk-ideographic
  img
    max-width: 100%
  h1,h2,h3,h4,h5,h6
    font-weight: 500
    color: $font
  h1
    +font-size(32px)
  h2
    +font-size(24px)
  h3
    +font-size(20px)
  h4
    +font-size(18px)
  h5
    +font-size(16px)
  h6
    +font-size(14px)




.galleryModal
  .gallery-warpper
    figure
      figcaption
        font-size: 1.25rem
        color: $font-dark
        margin-bottom: .85rem

// 首頁-最新消息
.home-news
  position: relative
  overflow: hidden
.card
  border: 0
  border-radius: 8px
.card-board
  border-radius: 8px
  position: relative
  overflow: hidden
  &:hover, &:focus
    .img
      &:after
        background: linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0))
      .card-img
        transform: scale(1.06)
    .card-body
      &:after
        width: 100%
  .card-over-link
    @extend %card-over-link
  .img
    @extend %img-scale
    &:after
      content: ''
      display: block
      width: 100%
      height: 40%
      position: absolute
      bottom: 0
      left: 0
      transition: .4s transform cubic-bezier(0.60, 0, 0.075, 1)
      transform: translateY(0%)
      background: linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0))
      +md-down
        background: linear-gradient(0deg, rgba(0,0,0,.5), rgba(0,0,0,0))
    img
      object-fit: cover
  .card-body
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    padding: 1rem 1rem 1.2rem 1rem
    transition: .4s transform cubic-bezier(0.60, 0, 0.075, 1)
    +sm-down
      padding: .8rem .8rem 1rem .8rem
    &:after
      +md-up
        content: ''
        display: block
        width: 0
        height: 4px
        position: absolute
        bottom: 0
        left: 0
        background-color: $primary
        transition: .4s width .2s cubic-bezier(0.60, 0, 0.075, 1)
    a
      color: #fff
    .date
      font-size: .8rem
      color: $success
      margin-bottom: .1rem
    .card-title
      color: #fff
      font-size: 1.1rem
      text-shadow: 0 1px 2px rgba(0,0,0,.8)
      margin-bottom: 0
      +sm-down
        font-size: 1rem
        display: block
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
      +sm-up
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        white-space: normal
      &.font-md-small
        +md-up
          font-size: .95em !important

// modal Search
.modal-content
  .hash-group
    padding-top: 1rem
    margin-left: -.5rem
    margin-right: -.5rem
    a
      &.text-link
        font-size: .9rem
        color: #558fb9
        display: inline-block
        padding: .25rem .5rem
  .form-search-group
    .form-select
      border-width: 0 0 1px 0
      border-radius: 0
      width: 100%
      height: 60px
      font-weight: 300
      font-size: 1.25rem
      border-color: $gray-600
      +sm-down
        font-size: 1.1rem
      &:focus
        outline: 0
        box-shadow: 0 0 0 rgba(0,0,0,0)
    .form-search-input
      position: relative
      .customize-input
        cursor: text
        border: none
        color: #e4e4e4
        font-weight: 300
        font-size: 1.25rem
        width: 100%
        height: 60px
        padding: 0 0 0 .8rem
        border-bottom: 1px solid $gray-600
        color: $gray-900
        border-radius: 0
        +sm-down
          font-size: 1.1rem
        &:focus
          outline: 0
      .btn
        position: absolute
        right: 0
        top: 8px
        width: 50px
        height: 50px
        border: none
        padding: 0
        margin: 0
        .icn
          display: inline-block
          width: 20px
          svg
            width: 100%
            g
              stroke: $gray-800
      ::placeholder
        color: $gray-900
      ::-webkit-input-placeholder
        color: $gray-900
      :-ms-input-placeholder
        color: $gray-900
      ::-moz-placeholder
        color: $gray-900
.features
  &.green-idea
    .parallax-window
      background: url('../images/istockphoto-1204865495-612x612.png')
      background-repeat: no-repeat
      background-position: center center
      background-attachment: fixed
      background-size: cover
      +sm-down
        background-position: left center
        background-attachment: initial
      &:after
        content: ''
        display: block
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        background: rgba(0,0,0,.5)
  &.join_us
    .parallax-window
      background: url('../images/join_us.jpg')
      background-repeat: no-repeat
      background-position: center center
      background-attachment: fixed
      background-size: cover
      +sm-down
        background-position: left center
        background-attachment: initial
      &:after
        content: ''
        display: block
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        background: rgba(0,0,0,.5)
  .parallax-window
    min-height: 617px
    background: transparent
    position: relative
    display: flex
    align-items: center
    justify-content: center
    background: url('../images/home-features.png')
    background-repeat: no-repeat
    background-position: center center
    background-attachment: fixed
    background-size: cover
    +sm-down
      min-height: 600px
      background-attachment: initial
    &:after
      content: ''
      display: block
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
      background: linear-gradient(0deg, rgba(32,33,15,.5) 0%, rgba(32,33,15,0) 100%)
      z-index: 0
    .content
      color: #fff
      text-align: center
      text-shadow: 0 2px 5px rgba(0,0,0,0.5)
      position: relative
      z-index: 88
      padding: 0 15px
  .qr-code
    max-width: 132px
    margin: 50px auto 0
    +md-down
      max-width: 122px
      margin: 30px auto 0

.parallax-mirror
  background: #000
  img
    opacity: .6
.Le_papillon
  .course-group
    color: #FFF
    max-width: 200px
    .course-title
      +font-size(18px)
      font-weight: 600
    .course-item
      list-style: none
      padding: 0
      +font-size(13px)
      font-weight: 300
      color: #FFFFFF
      +md-down
        +font-size(14px)
        color: #a7a7a7
      .list-item
        padding-bottom: .5rem
        display: flex
        align-items: center
        justify-content: space-between
  .course-photo
    padding-top: 80px
    +md-down
      margin-left: -0.75rem
      margin-right: -0.75rem
      padding-top: 30px
    img
      width: 100%
      height: 100%
      object-fit: cover
  .card-course
    position: relative
    .card-content
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      color: #FFF
      text-shadow: 0 2px 8px rgba(0,0,0,.5)
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      .title
        +font-size(38px)
      p
        +font-size(18px)
        +md-down
          +font-size(14px)

.home-beauty
  .beauty-group
    position: relative
    padding-bottom: 220px
    +lg-down
      padding-bottom: 150px
    +sm-down
      padding-bottom: 100px
    .graphy
      &.back
        width: 60%
        box-shadow: 0 2px 10px rgba(0,0,0,.3)
        +sm-down
          width: 66%

      &.front
        width: 45%
        position: absolute
        top: 50%
        left: 45%
        box-shadow: 0 10px 30px rgba(0,0,0,.6)
        +sm-down
          width: 50%
          left: 50%

.topbanner
  position: relative
  .text-group
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    z-index: 8
    color: #fff
    display: flex
    align-items: center
    > .container
      position: relative
      z-index: 12
    .breadcrumb
      color: #FFF
    .breadcrumb-item
      color: #FFF
      +font-size(28px)
      font-weight: 300
      +md-down
        +font-size(20px)
      +sm-down
        +font-size(16px)
      &::before
        color: #FFF
        font-weight: 300
      &.active
        a
          font-weight: 300
      a
        color: #FFF
    &:after
      content: ''
      display: block
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
      z-index: 0
      background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%)
.ratio
  &.ratio-banner
    &::before
      --bs-aspect-ratio: 23.6%
      +md-down
        --bs-aspect-ratio: 42.6%
      +md-down
        --bs-aspect-ratio: 52.6%
    img
      object-fit: cover
  &.ratio-about-banner
    &::before
      --bs-aspect-ratio: 38%
      +md-down
        --bs-aspect-ratio: 56.6%
      +md-down
        --bs-aspect-ratio: 64.6%
    img
      object-fit: cover
  &.ratio-news
    &::before
      --bs-aspect-ratio: 66%
    img
      object-fit: cover
.product_card
  background: transparent
  &:hover, &:focus
    .product-photo
      img
        transform: translateY(0%)
        transform: scale(1.06)
    .card-body
      color: $primary
  .product-photo
    background: #fff
    overflow: hidden
    img
      transition: .6s transform cubic-bezier(0.6, 0, 0.075, 1)
  .card-body
    text-align: center
    color: #000000
    .title
      +font-size(20px)
      +md-down
        +font-size(16px)
    .name
      +font-size(15px)
      +md-down
        +font-size(13px)
.product-information
  padding-top: 30px
  .product-nav
    margin-left: -10px
    width: calc(100% + 10px)
    .slick-slide
      padding-left: 10px
      cursor: pointer
      &.slick-current
        .ratio
          border-bottom: 3px solid $primary
      .ratio
        background: #F0F0F0
  .product-cover
    background: #F0F0F0
    margin-bottom: 10px
  .product-mate
    display: flex
    height: 100%
    flex-direction: column
    justify-content: space-between
    +md-down
      margin-top: 30px
    .brand
      +font-size(30px)
    .name
      +font-size(25px)

  .product-descript
    border-top: 1px solid #C9C9C9
    margin-top: 15px
    padding-top: 15px
    line-height: 1.56
    p
      +font-size(16px)
      color: $font
    .btn-wrapper
      margin-top: 20px
      .btn
        +md-down
          width: 100%
.news_card
  box-shadow: 0 1px 3px rgba(0,0,0,0.1)
  border-radius: 0
  background: transparent
  &:hover, &:focus
    .news-photo
      img
        transform: translateY(0%)
        transform: scale(1.06)
    .card-body
      .title
        color: $primary
      .description
        color: #ffffff
  .news-photo
    overflow: hidden
  img
    transition: .6s transform cubic-bezier(0.6, 0, 0.075, 1)
  .title
    +font-size(17px)
    font-weight: 500
    color: $primary
    margin-bottom: 0
  .date
    color: $gray-200
    +font-size(13px)
    margin-top: .2rem
  .description
    +font-size(14px)
    color: $gray-100
    font-weight: 300
    margin-bottom: 0
    margin-top: 20px

#goTop
  width: 42px
  height: 42px
  border-radius: 50%
  background: $primary-dark
  position: fixed
  bottom: 30px
  right: 30px
  z-index: 982
  display: none
  align-items: center
  justify-content: center
  cursor: pointer
  &.show
    display: flex
  svg
    width: 18px
    height: 18px
    stroke: #fff

.about-certification

  .certification-wrapper
    .wrapper-title
      +font-size(48px)
    .gird-row
      display: grid
      grid-template-columns: repeat(5, 1fr)
      grid-gap: 30px
      width: 100%
      +lg-down
        display: flex
        overflow-x: scroll
        grid-gap: 15px
      .certification-item
        +lg-down
          width: 150px
          flex: 0 0 150px
        .item-body
          text-align: center
          padding: 1rem 0
        .title
          +font-size(18px)
          color: #FFF
          margin-bottom: 5px
          +lg-down
            +font-size(16px)
        .name
          +font-size(14px)
          margin-bottom: 0
          +lg-down
            +font-size(13px)

.card-service
  position: relative
  overflow: hidden
  &:hover, &:focus
    img
      transform: scale(1.05)
    .card-body
      transform: translateY(0%)
  img
    transition: 0.2s transform ease-in
  .card-body
    background: rgba(0,0,0,.4)
    color: #FFF
    text-align: center
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 35%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    transition: 0.2s transform ease-in
    transform: translateY(100%)
    .title
      +font-size(30px)
      color: #FFF
      margin-bottom: 5px
      color: #FFFFFF
      +lg-down
        +font-size(20px)
    .name
      +font-size(16px)
      margin-bottom: 0
      color: #FFFFFF
      +lg-down
        +font-size(13px)